import { FC } from 'react';
import { BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';
import React from 'react';

export const AlertIcon: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  backgroundColorHexCode = colorToHexCode.keyline,
  primaryColorHexCode = colorToHexCode.black,
  // includeSecondaryMark = false,
  'data-testid': testId,
}) => (
  <svg
    data-testid={testId}
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.782227"
      width="12"
      height="12"
      rx="6"
      fill={backgroundColorHexCode}
    />
    <path
      d="M6.33242 2.50488V5.68488L6.58242 7.51488H6.98242L7.24242 5.68488V2.50488H6.33242ZM6.23242 8.38488V9.49488H7.33242V8.38488H6.23242Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
