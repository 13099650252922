import {
  apikeysPath,
  billingPath,
  dataExportsPath,
  linksPath,
  membersPath,
  workspacePath,
} from 'components/constants';
import { useFlagEnabledWithOverrides } from 'hooks/useFlagEnabledWithOverrides';
import { useCallback, useMemo } from 'react';
import { useClient } from 'urql';
import { makePath } from 'utils/routes';

import type {
  GetLinksQuery,
  GetLinksQueryVariables,
  ListLedgersQuery,
  ListLedgersQueryVariables,
  ListSchemasQuery,
  ListSchemasQueryVariables,
  ListSchemasWithLedgersQuery,
  ListSchemasWithLedgersQueryVariables,
} from '../../hooks/customerApi';
import type {
  GetWorkspaceByIdQuery,
  GetWorkspaceByIdQueryVariables,
} from '../../hooks/internalApi';
import type { NavTree } from './SideNav';
import {
  GetLinksDocument,
  ListLedgersDocument,
  ListSchemasDocument,
  ListSchemasWithLedgersDocument,
} from '../../hooks/customerApi';
import { GetWorkspaceByIdDocument } from '../../hooks/internalApi';
import { useCustomerApiContext } from '../../hooks/useCustomerApiContext';
import { useInternalApiContext } from '../../hooks/useInternalApiContext';

export type ActivePath =
  | 'home'
  | 'schemas'
  | 'schemas.current'
  | 'schemas.history'
  | 'ledgers'
  | 'ledgers.entries'
  | 'ledgers.groups'
  | 'ledgers.groups.entries'
  | 'ledgers.groups.balances'
  | 'ledgers.accounts'
  | 'ledgers.accounts.childAccounts'
  | 'ledgers.accounts.lines'
  | 'ledgers.accounts.balances'
  | 'ledgers.accounts.balances.total'
  | 'ledgers.accounts.balances.own'
  | 'ledgers.accounts.balances.child'
  | 'ledgers.accounts.unreconciled'
  | 'apiExplorer'
  | 'links'
  | 'links.accounts'
  | 'links.accounts.transactions'
  | 'links.detail'
  | 'settings'
  | 'settings.workspace'
  | 'settings.apiClient'
  | 'settings.member'
  | 'settings.dataExport'
  | 'settings.billing';

const setActiveNavTreePath = (navTree: NavTree, active: string) => {
  const keyPath = active.split('.');
  let current: NavTree | undefined = navTree;
  keyPath.forEach((key) => {
    if (current && key in current) {
      current[key].props.active = true;
      current = current[key].subNav;
    } else {
      throw new Error(`Invalid key path: ${key}`);
    }
  });
};

const makeAbsoluteWouterPath = (args: string[]) => `~${makePath(args)}`;

export const useDashboardNavTree = (
  // active is a string with a period delimitor that represents the key path in the
  // navTree object
  active: ActivePath,
  workspaceId: string,
  ledgerId?: string,
  accountId?: string,
  linkId?: string,
  groupId?: string,
  externalAccountId?: string
): NavTree => {
  const client = useClient();
  const { context: customerApiContext } = useCustomerApiContext();
  const { context: internalApiContext } = useInternalApiContext();
  const isLinkDetailPageEnabled =
    useFlagEnabledWithOverrides('link-detail-page');
  const onLedgersNavHover = useCallback(async () => {
    const variables = {
      first: 20, // This is implicitly passed in by the usePaginatedQuery hook
    };
    client
      .query<ListLedgersQuery, ListLedgersQueryVariables>(
        ListLedgersDocument,
        variables,
        {
          ...customerApiContext,
          pause: !customerApiContext.token,
          requestPolicy: 'cache-first',
        }
      )
      .toPromise();
  }, [client, customerApiContext]);
  const onSchemasNavHover = useCallback(async () => {
    const variables = {
      first: 20, // This is implicitly passed in by the usePaginatedQuery hook
    };
    client
      .query<ListSchemasQuery, ListSchemasQueryVariables>(
        ListSchemasDocument,
        variables,
        {
          ...customerApiContext,
          pause: !customerApiContext.token,
          requestPolicy: 'cache-first',
        }
      )
      .toPromise();
  }, [client, customerApiContext]);

  const onLinksNavHover = useCallback(async () => {
    const variables = {};
    client
      .query<GetLinksQuery, GetLinksQueryVariables>(
        GetLinksDocument,
        variables,
        {
          ...customerApiContext,
          pause: !customerApiContext.token,
          requestPolicy: 'cache-first',
        }
      )
      .toPromise();
  }, [client, customerApiContext]);

  const onSettingsNavHover = useCallback(async () => {
    const variables = {
      workspaceId,
    };
    client
      .query<GetWorkspaceByIdQuery, GetWorkspaceByIdQueryVariables>(
        GetWorkspaceByIdDocument,
        variables,
        {
          ...internalApiContext,
          pause: !internalApiContext.token,
          requestPolicy: 'cache-first',
        }
      )
      .toPromise();
  }, [client, internalApiContext, workspaceId]);

  const onHomePageHover = useCallback(() => {
    {
      const variables = {};
      client
        .query<
          ListSchemasWithLedgersQuery,
          ListSchemasWithLedgersQueryVariables
        >(ListSchemasWithLedgersDocument, variables, {
          ...customerApiContext,
          pause: !customerApiContext.token,
          requestPolicy: 'cache-first',
        })
        .toPromise();
    }
    {
      const variables = {
        first: 3,
      };
      client
        .query<ListSchemasQuery, ListSchemasQueryVariables>(
          ListSchemasDocument,
          variables,
          {
            ...customerApiContext,
            pause: !customerApiContext.token,
            requestPolicy: 'cache-first',
          }
        )
        .toPromise();
    }
    {
      const variables = {
        first: 3,
      };
      client
        .query<ListLedgersQuery, ListLedgersQueryVariables>(
          ListLedgersDocument,
          variables,
          {
            ...customerApiContext,
            pause: !customerApiContext.token,
            requestPolicy: 'cache-first',
          }
        )
        .toPromise();
    }
  }, [client, customerApiContext]);

  return useMemo(() => {
    const navTree: NavTree = {
      home: {
        props: {
          href: makeAbsoluteWouterPath(['w', workspaceId, 'home']),
          children: 'Home',
        },
        onHover: onHomePageHover,
      },
      schemas: {
        props: {
          href: makeAbsoluteWouterPath(['w', workspaceId, 'schemas']),
          children: 'Schemas',
        },
        onHover: onSchemasNavHover,
      },
      ledgers: {
        props: {
          href: makeAbsoluteWouterPath(['w', workspaceId, 'ledgers']),
          children: 'Ledgers',
        },
        visibleOverride: active === 'ledgers' ? false : undefined,
        onHover: onLedgersNavHover,
        subNav: {
          accounts: {
            props: {
              href: ledgerId
                ? makeAbsoluteWouterPath([
                    'w',
                    workspaceId,
                    'ledgers',
                    ledgerId,
                    'accounts',
                  ])
                : '',
              children: 'Accounts',
            },
            visibleOverride: active === 'ledgers.accounts' ? false : undefined,
            subNav: {
              childAccounts: {
                props: {
                  href:
                    ledgerId && accountId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          'ledgers',
                          ledgerId,
                          'accounts',
                          accountId,
                          'childaccounts',
                        ])
                      : '',
                  children: 'Children',
                },
              },
              lines: {
                props: {
                  href:
                    ledgerId && accountId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          'ledgers',
                          ledgerId,
                          'accounts',
                          accountId,
                          'lines',
                        ])
                      : '',
                  children: 'Lines',
                },
              },
              balances: {
                props: {
                  href:
                    ledgerId && accountId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          'ledgers',
                          ledgerId,
                          'accounts',
                          accountId,
                          'balances',
                        ])
                      : '',
                  children: 'Balances',
                },
                // Only show subtree if we're already within it
                visibleOverride: active.startsWith(
                  'ledgers.accounts.balances.'
                ),
                subNav: {
                  total: {
                    props: {
                      href:
                        ledgerId && accountId
                          ? makeAbsoluteWouterPath([
                              'w',
                              workspaceId,
                              'ledgers',
                              ledgerId,
                              'accounts',
                              accountId,
                              'balances',
                              'total',
                            ])
                          : '',
                      children: 'Total',
                    },
                  },
                  own: {
                    props: {
                      href:
                        ledgerId && accountId
                          ? makeAbsoluteWouterPath([
                              'w',
                              workspaceId,
                              'ledgers',
                              ledgerId,
                              'accounts',
                              accountId,
                              'balances',
                              'own',
                            ])
                          : '',
                      children: 'Own',
                    },
                  },
                  child: {
                    props: {
                      href:
                        ledgerId && accountId
                          ? makeAbsoluteWouterPath([
                              'w',
                              workspaceId,
                              'ledgers',
                              ledgerId,
                              'accounts',
                              accountId,
                              'balances',
                              'child',
                            ])
                          : '',
                      children: 'Child',
                    },
                  },
                },
              },
              // Ideally, we would only display this page for linked accounts.
              // However, we can't infer linked-ness from the URL path, so we can't
              // do that without consideration for loading states.
              unreconciled: {
                props: {
                  href:
                    ledgerId && accountId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          'ledgers',
                          ledgerId,
                          'accounts',
                          accountId,
                          'unreconciled',
                        ])
                      : '',
                  children: 'Unreconciled',
                },
              },
            },
          },
          entries: {
            props: {
              href: ledgerId
                ? makeAbsoluteWouterPath([
                    'w',
                    workspaceId,
                    'ledgers',
                    ledgerId,
                    'entries',
                  ])
                : '',
              children: 'Entries',
            },
          },

          groups: {
            props: {
              href: ledgerId
                ? makeAbsoluteWouterPath([
                    'w',
                    workspaceId,
                    'ledgers',
                    ledgerId,
                    'groups',
                  ])
                : '',
              children: 'Groups',
            },
            visibleOverride: active.startsWith('ledgers.groups.'),
            subNav: {
              entries: {
                props: {
                  href:
                    ledgerId && groupId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          'ledgers',
                          ledgerId,
                          'groups',
                          groupId,
                          'entries',
                        ])
                      : '',
                  children: 'Entries',
                },
              },
              balances: {
                props: {
                  href:
                    ledgerId && groupId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          'ledgers',
                          ledgerId,
                          'groups',
                          groupId,
                          'balances',
                        ])
                      : '',
                  children: 'Balances',
                },
              },
            },
          },
        },
      },
      apiExplorer: {
        props: {
          href: makeAbsoluteWouterPath(['w', workspaceId, 'explorer']),
          children: 'Explorer',
        },
      },
      links: {
        props: {
          href: makeAbsoluteWouterPath(['w', workspaceId, linksPath.slice(1)]),
          children: 'Links',
        },
        onHover: onLinksNavHover,
        visibleOverride: active === 'links' ? false : undefined,
        subNav: {
          accounts: {
            props: {
              href: linkId
                ? makeAbsoluteWouterPath([
                    'w',
                    workspaceId,
                    linksPath.slice(1),
                    'id',
                    linkId,
                  ])
                : '',
              children: 'External Accounts',
            },
            visibleOverride: active === 'links.accounts' ? false : undefined,
            subNav: {
              transactions: {
                props: {
                  href:
                    linkId && externalAccountId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          linksPath.slice(1),
                          'id',
                          linkId,
                          'accounts',
                          externalAccountId,
                        ])
                      : '',
                  children: 'Transactions',
                },
              },
            },
          },
          ...(isLinkDetailPageEnabled
            ? {
                detail: {
                  props: {
                    href: linkId
                      ? makeAbsoluteWouterPath([
                          'w',
                          workspaceId,
                          linksPath.slice(1),
                          'id',
                          linkId,
                          'details',
                        ])
                      : '',
                    children: 'Details',
                  },
                },
              }
            : {}),
        },
      },
      settings: {
        props: {
          href: makeAbsoluteWouterPath([
            'w',
            workspaceId,
            's',
            workspacePath.slice(1),
          ]),
          children: 'Settings',
        },
        onHover: onSettingsNavHover,
        subNav: {
          workspace: {
            props: {
              href: makeAbsoluteWouterPath([
                'w',
                workspaceId,
                's',
                workspacePath.slice(1),
              ]),
              children: 'Workspace',
            },
          },
          apiClient: {
            props: {
              href: makeAbsoluteWouterPath([
                'w',
                workspaceId,
                's',
                apikeysPath.slice(1),
              ]),
              children: 'API Clients',
            },
          },
          member: {
            props: {
              href: makeAbsoluteWouterPath([
                'w',
                workspaceId,
                's',
                membersPath.slice(1),
              ]),
              children: 'Members',
            },
          },
          dataExport: {
            props: {
              href: makeAbsoluteWouterPath([
                'w',
                workspaceId,
                's',
                dataExportsPath.slice(1),
              ]),
              children: 'Data Exports',
            },
          },
          billing: {
            props: {
              href: makeAbsoluteWouterPath([
                'w',
                workspaceId,
                's',
                billingPath.slice(1),
              ]),
              children: 'Billing',
            },
          },
        },
      },
    };
    setActiveNavTreePath(navTree, active);
    return navTree;
  }, [
    workspaceId,
    onHomePageHover,
    onSchemasNavHover,
    active,
    onLedgersNavHover,
    ledgerId,
    accountId,
    groupId,
    onLinksNavHover,
    linkId,
    externalAccountId,
    onSettingsNavHover,
    isLinkDetailPageEnabled,
  ]);
};
