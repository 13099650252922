import { Profile } from 'components/Profile/Profile';
import {
  type ActivePath,
  SideNav,
  useDashboardNavTree,
} from 'components/SideNav';
import { WorkspaceSwitcher } from 'components/WorkspaceSwitcher/WorkspaceSwitcher';
import { useWorkspaceId } from 'hooks/useWorkspace';
import React from 'react';

import { Icon } from '@fragment/ui/src/components/Icon';
import { Text } from '@fragment/ui/src/components/Text';

type Props = {
  active: ActivePath;
  ledgerId?: string;
  accountId?: string;
  linkId?: string;
  externalAccountId?: string;
  schemaKey?: string;
  groupId?: string;
  /**
   * If true, changes the data-testid of the sidebar.
   */
  suspense?: boolean;
};

export const SideBar = ({
  active,
  ledgerId,
  accountId,
  linkId,
  externalAccountId,
  schemaKey,
  groupId,
  suspense,
}: Props) => {
  const workspaceId = useWorkspaceId();
  const navTree = useDashboardNavTree(
    active,
    workspaceId,
    ledgerId,
    accountId,
    linkId,
    groupId,
    externalAccountId
  );

  return (
    <div
      data-testid={suspense ? 'suspense-sidebar' : 'sidebar'}
      className="h-full flex flex-col flex-nowrap justify-between"
    >
      <div>
        <WorkspaceSwitcher className="pl-f2 tablet:pl-f0 mb-f8" />
        <SideNav navTree={navTree} />
      </div>
      <div>
        <Text
          data-testid="sidebar-docs-link"
          as="a"
          href={new URL('/docs', 'https://fragment.dev').toString()}
          display="block"
          color="text-main-500 hover:text-main"
          fontSize="text-fnormal"
          margin="mb-f2"
          transition="transition-colors"
          target="_blank"
          rel="noreferrer"
        >
          Docs <Icon type="external" />
        </Text>
        <Profile />
      </div>
    </div>
  );
};
