import { useFlags } from 'flagsmith/react';
import { type DashboardFlags, localFlagValues } from 'localFlagValues';
import { FragmentEnv } from 'utils/env';
import { getLocalStorage } from 'utils/localStorage';

export function useFlagsWithOverrides(names: string[], traits?: string[]) {
  const flagsmithFlagsAndTraits = useFlags(names, traits);
  const overrides = getLocalStorage('flagsmith_overrides') ?? {};
  return Object.fromEntries(
    [...names, ...(traits ?? [])].map((key) => {
      const flagValue =
        window.Cypress || FragmentEnv.flagsmith.shouldMock
          ? localFlagValues[key as DashboardFlags]
          : flagsmithFlagsAndTraits[key];
      return [key, overrides[key] ?? flagValue];
    })
  ) as ReturnType<typeof useFlags>;
}
