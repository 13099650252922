/* This file was generated by `yarn gen-graphql`. Don't edit it directly */

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AcceptWorkspaceInviteResult = {
  __typename?: 'AcceptWorkspaceInviteResult';
  createdNewUser: Scalars['Boolean']['output'];
  workspace: Workspace;
};

export type AuthLinkUnit = {
  __typename?: 'AuthLinkUnit';
  env: LinkUnitEnv;
  id: Scalars['ID']['output'];
  unitOrgId: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type BadRequestError = Error & {
  __typename?: 'BadRequestError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  retryable: Scalars['Boolean']['output'];
};

export type CreateAuthLinkUnitResult = {
  __typename?: 'CreateAuthLinkUnitResult';
  authLink: AuthLinkUnit;
};

export type CreateWorkspaceInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pod: Scalars['String']['input'];
};

export type CreateWorkspaceResponse = {
  __typename?: 'CreateWorkspaceResponse';
  createdNewUser: Scalars['Boolean']['output'];
  user: User;
  workspace: Workspace;
};

export enum CurrencyCode {
  Aave = 'AAVE',
  Ada = 'ADA',
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bch = 'BCH',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btc = 'BTC',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Custom = 'CUSTOM',
  Cve = 'CVE',
  Czk = 'CZK',
  Dai = 'DAI',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eth = 'ETH',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Imp = 'IMP',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Link = 'LINK',
  Lkr = 'LKR',
  Logical = 'LOGICAL',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltc = 'LTC',
  Lyd = 'LYD',
  Mad = 'MAD',
  Matic = 'MATIC',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sol = 'SOL',
  Sos = 'SOS',
  Spl = 'SPL',
  Srd = 'SRD',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Tvd = 'TVD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Uni = 'UNI',
  Usd = 'USD',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xlm = 'XLM',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
}

export type DeleteMemberFromWorkspaceResult = {
  __typename?: 'DeleteMemberFromWorkspaceResult';
  deletedUserId: Scalars['String']['output'];
  workspace: Workspace;
};

export type Error = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  retryable: Scalars['Boolean']['output'];
};

export type InternalError = Error & {
  __typename?: 'InternalError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  retryable: Scalars['Boolean']['output'];
};

export enum LinkUnitEnv {
  Production = 'production',
  Sandbox = 'sandbox',
}

export type Member = Node & {
  __typename?: 'Member';
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptWorkspaceInvite: MutationAcceptWorkspaceInviteResult;
  createAuthLinkUnit: MutationCreateAuthLinkUnitResult;
  createUser: MutationCreateUserResult;
  createUserInvite: MutationCreateUserInviteResult;
  createWorkspace: MutationCreateWorkspaceResult;
  deleteMemberFromWorkspace: MutationDeleteMemberFromWorkspaceResult;
  toggleWorkspaceInvite: MutationToggleWorkspaceInviteResult;
  updateWorkspace: MutationUpdateWorkspaceResult;
};

export type MutationAcceptWorkspaceInviteArgs = {
  inviteCode: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationCreateAuthLinkUnitArgs = {
  env: LinkUnitEnv;
  unitOrgId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type MutationCreateUserArgs = {
  user: UserInput;
};

export type MutationCreateUserInviteArgs = {
  extraPods?: InputMaybe<Array<Scalars['String']['input']>>;
  note: Scalars['String']['input'];
};

export type MutationCreateWorkspaceArgs = {
  userInviteId?: InputMaybe<Scalars['String']['input']>;
  workspace: CreateWorkspaceInput;
};

export type MutationDeleteMemberFromWorkspaceArgs = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationToggleWorkspaceInviteArgs = {
  enable: Scalars['Boolean']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationUpdateWorkspaceArgs = {
  update: UpdateWorkspaceInput;
  workspaceId: Scalars['String']['input'];
};

export type MutationAcceptWorkspaceInviteResult =
  | AcceptWorkspaceInviteResult
  | BadRequestError
  | InternalError
  | NotFoundError;

export type MutationCreateAuthLinkUnitResult =
  | BadRequestError
  | CreateAuthLinkUnitResult
  | InternalError
  | NotFoundError;

export type MutationCreateUserInviteResult =
  | BadRequestError
  | InternalError
  | NotFoundError
  | UserInvite;

export type MutationCreateUserResult =
  | BadRequestError
  | InternalError
  | NotFoundError
  | User;

export type MutationCreateWorkspaceResult =
  | BadRequestError
  | CreateWorkspaceResponse
  | InternalError
  | NotFoundError;

export type MutationDeleteMemberFromWorkspaceResult =
  | BadRequestError
  | DeleteMemberFromWorkspaceResult
  | InternalError
  | NotFoundError;

export type MutationToggleWorkspaceInviteResult =
  | BadRequestError
  | InternalError
  | NotFoundError
  | ToggleWorkspaceInviteResult;

export type MutationUpdateWorkspaceResult =
  | BadRequestError
  | InternalError
  | NotFoundError
  | Workspace;

export type Node = {
  id: Scalars['ID']['output'];
};

export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  retryable: Scalars['Boolean']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Pod = {
  __typename?: 'Pod';
  awsAccountId: Scalars['String']['output'];
  cognitoAuthDomain: Scalars['String']['output'];
  customerApiAudience: Scalars['String']['output'];
  customerApiOAuthScope: Scalars['String']['output'];
  customerApiUrl: Scalars['String']['output'];
  dashboardCustomerApiUrl: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalApiAudience: Scalars['String']['output'];
  internalApiUrl: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  node?: Maybe<Member>;
  nodes: Array<Maybe<Member>>;
  /** Get user invite by ID */
  userInvite: QueryUserInviteResult;
  /** User Invites */
  userInvites: Array<UserInvite>;
  /** User info for the current context's user */
  userinfo?: Maybe<User>;
  workspace: QueryWorkspaceResult;
};

export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryUserInviteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserInvitesArgs = {
  used: Scalars['Boolean']['input'];
};

export type QueryWorkspaceArgs = {
  id: Scalars['String']['input'];
};

export type QueryUserInviteResult =
  | BadRequestError
  | InternalError
  | NotFoundError
  | UserInvite;

export type QueryWorkspaceResult =
  | BadRequestError
  | InternalError
  | NotFoundError
  | Workspace;

export type ToggleWorkspaceInviteResult = {
  __typename?: 'ToggleWorkspaceInviteResult';
  workspace: Workspace;
};

export type UpdateWorkspaceInput = {
  defaultCurrency?: InputMaybe<CurrencyCode>;
  name?: InputMaybe<Scalars['String']['input']>;
  octaneCustomerId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  authId: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  pods: Array<Pod>;
  workspaces?: Maybe<Array<Workspace>>;
};

export type UserInput = {
  authId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  image: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  pods?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  id: Scalars['String']['output'];
  note: Scalars['String']['output'];
  pods: Array<Pod>;
  used: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type UserMatchInput = {
  id: Scalars['String']['input'];
};

export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['String']['output'];
  inviteCode?: Maybe<Scalars['String']['output']>;
  inviteEnabled: Scalars['Boolean']['output'];
  members: WorkspaceMembersConnection;
  name: Scalars['String']['output'];
  octaneCustomerId?: Maybe<Scalars['String']['output']>;
  pod: Pod;
};

export type WorkspaceMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkspaceMatchInput = {
  id: Scalars['String']['input'];
};

export type WorkspaceMembersConnection = {
  __typename?: 'WorkspaceMembersConnection';
  edges: Array<Maybe<WorkspaceMembersConnectionEdge>>;
  nodes: Array<Maybe<Member>>;
  pageInfo: PageInfo;
};

export type WorkspaceMembersConnectionEdge = {
  __typename?: 'WorkspaceMembersConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Member;
};

export type NewWorkspacePageMutationVariables = Exact<{
  userInviteId?: InputMaybe<Scalars['String']['input']>;
  workspace: CreateWorkspaceInput;
}>;

export type NewWorkspacePageMutation = {
  __typename?: 'Mutation';
  createWorkspace:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | {
        __typename: 'CreateWorkspaceResponse';
        workspace: {
          __typename?: 'Workspace';
          id: string;
          name: string;
          pod: {
            __typename?: 'Pod';
            id: string;
            displayName: string;
            internalApiUrl: string;
            customerApiUrl: string;
          };
        };
      }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string };
};

export type SessionUserinfoQueryVariables = Exact<{ [key: string]: never }>;

export type SessionUserinfoQuery = {
  __typename?: 'Query';
  userinfo?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    image?: string | null;
    pods: Array<{ __typename?: 'Pod'; id: string; displayName: string }>;
    workspaces?: Array<{
      __typename?: 'Workspace';
      id: string;
      name: string;
      pod: {
        __typename?: 'Pod';
        id: string;
        displayName: string;
        internalApiUrl: string;
        cognitoAuthDomain: string;
        internalApiAudience: string;
        dashboardCustomerApiUrl: string;
        customerApiUrl: string;
        customerApiAudience: string;
        customerApiOAuthScope: string;
        awsAccountId: string;
      };
    }> | null;
  } | null;
};

export type AcceptWorkspaceInviteMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  inviteCode: Scalars['String']['input'];
}>;

export type AcceptWorkspaceInviteMutation = {
  __typename?: 'Mutation';
  acceptWorkspaceInvite:
    | {
        __typename: 'AcceptWorkspaceInviteResult';
        createdNewUser: boolean;
        workspace: { __typename?: 'Workspace'; id: string; name: string };
      }
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string };
};

export type GetPodsFromUserInviteQueryVariables = Exact<{
  userInviteId: Scalars['ID']['input'];
}>;

export type GetPodsFromUserInviteQuery = {
  __typename?: 'Query';
  userInvite:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'UserInvite';
        id: string;
        pods: Array<{ __typename: 'Pod'; id: string; displayName: string }>;
      };
};

export type GetWorkspaceInviteByIdQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;

export type GetWorkspaceInviteByIdQuery = {
  __typename?: 'Query';
  workspace:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'Workspace';
        id: string;
        inviteEnabled: boolean;
        inviteCode?: string | null;
      };
};

export type DeleteWorkspaceMemberMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type DeleteWorkspaceMemberMutation = {
  __typename?: 'Mutation';
  deleteMemberFromWorkspace:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | {
        __typename: 'DeleteMemberFromWorkspaceResult';
        workspace: {
          __typename?: 'Workspace';
          id: string;
          members: {
            __typename?: 'WorkspaceMembersConnection';
            nodes: Array<{
              __typename: 'Member';
              id: string;
              created: string;
              user: { __typename: 'User'; id: string; email?: string | null };
            } | null>;
          };
        };
      }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string };
};

type OnError_BadRequestError_Fragment = {
  __typename: 'BadRequestError';
  code: string;
  message: string;
};

type OnError_InternalError_Fragment = {
  __typename: 'InternalError';
  code: string;
  message: string;
};

type OnError_NotFoundError_Fragment = {
  __typename: 'NotFoundError';
  code: string;
  message: string;
};

export type OnErrorFragment =
  | OnError_BadRequestError_Fragment
  | OnError_InternalError_Fragment
  | OnError_NotFoundError_Fragment;

export type GetWorkspaceMembersQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;

export type GetWorkspaceMembersQuery = {
  __typename?: 'Query';
  workspace:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'Workspace';
        members: {
          __typename?: 'WorkspaceMembersConnection';
          nodes: Array<{
            __typename: 'Member';
            id: string;
            created: string;
            user: { __typename: 'User'; id: string; email?: string | null };
          } | null>;
        };
      };
};

export type ToggleLinkSharingMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  enable: Scalars['Boolean']['input'];
}>;

export type ToggleLinkSharingMutation = {
  __typename?: 'Mutation';
  toggleWorkspaceInvite:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'ToggleWorkspaceInviteResult';
        workspace: {
          __typename?: 'Workspace';
          id: string;
          inviteEnabled: boolean;
          inviteCode?: string | null;
        };
      };
};

export type UpdateWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  update: UpdateWorkspaceInput;
}>;

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation';
  updateWorkspace:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | { __typename: 'Workspace'; id: string; name: string };
};

export const OnErrorFragmentDoc = gql`
  fragment onError on Error {
    __typename
    code
    message
  }
`;
export const NewWorkspacePageDocument = gql`
  mutation NewWorkspacePage(
    $userInviteId: String
    $workspace: CreateWorkspaceInput!
  ) {
    createWorkspace(userInviteId: $userInviteId, workspace: $workspace) {
      __typename
      ... on CreateWorkspaceResponse {
        workspace {
          id
          name
          pod {
            id
            displayName
            internalApiUrl
            customerApiUrl
          }
        }
      }
      ... on Error {
        code
        message
      }
    }
  }
`;

export function useNewWorkspacePageMutation() {
  return Urql.useMutation<
    NewWorkspacePageMutation,
    NewWorkspacePageMutationVariables
  >(NewWorkspacePageDocument);
}
export const SessionUserinfoDocument = gql`
  query SessionUserinfo {
    userinfo {
      id
      firstName
      lastName
      email
      image
      pods {
        id
        displayName
      }
      workspaces {
        id
        pod {
          id
          displayName
          internalApiUrl
          cognitoAuthDomain
          internalApiAudience
          dashboardCustomerApiUrl
          customerApiUrl
          customerApiAudience
          customerApiOAuthScope
          awsAccountId
        }
        name
      }
    }
  }
`;

export function useSessionUserinfoQuery(
  options?: Omit<Urql.UseQueryArgs<SessionUserinfoQueryVariables>, 'query'>
) {
  return Urql.useQuery<SessionUserinfoQuery, SessionUserinfoQueryVariables>({
    query: SessionUserinfoDocument,
    ...options,
  });
}
export const AcceptWorkspaceInviteDocument = gql`
  mutation AcceptWorkspaceInvite($workspaceId: ID!, $inviteCode: String!) {
    acceptWorkspaceInvite(workspaceId: $workspaceId, inviteCode: $inviteCode) {
      __typename
      ... on AcceptWorkspaceInviteResult {
        workspace {
          id
          name
        }
        createdNewUser
      }
      ... on Error {
        code
        message
      }
    }
  }
`;

export function useAcceptWorkspaceInviteMutation() {
  return Urql.useMutation<
    AcceptWorkspaceInviteMutation,
    AcceptWorkspaceInviteMutationVariables
  >(AcceptWorkspaceInviteDocument);
}
export const GetPodsFromUserInviteDocument = gql`
  query GetPodsFromUserInvite($userInviteId: ID!) {
    userInvite(id: $userInviteId) {
      __typename
      ... on UserInvite {
        __typename
        id
        pods {
          __typename
          id
          displayName
        }
      }
      ... on Error {
        code
        message
      }
    }
  }
`;

export function useGetPodsFromUserInviteQuery(
  options: Omit<Urql.UseQueryArgs<GetPodsFromUserInviteQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetPodsFromUserInviteQuery,
    GetPodsFromUserInviteQueryVariables
  >({ query: GetPodsFromUserInviteDocument, ...options });
}
export const GetWorkspaceInviteByIdDocument = gql`
  query GetWorkspaceInviteById($workspaceId: String!) {
    workspace(id: $workspaceId) {
      __typename
      ... on Workspace {
        __typename
        id
        inviteEnabled
        inviteCode
      }
      ... on Error {
        code
        message
      }
    }
  }
`;

export function useGetWorkspaceInviteByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GetWorkspaceInviteByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GetWorkspaceInviteByIdQuery,
    GetWorkspaceInviteByIdQueryVariables
  >({ query: GetWorkspaceInviteByIdDocument, ...options });
}
export const DeleteWorkspaceMemberDocument = gql`
  mutation DeleteWorkspaceMember($workspaceId: ID!, $userId: ID!) {
    deleteMemberFromWorkspace(workspaceId: $workspaceId, userId: $userId) {
      __typename
      ... on DeleteMemberFromWorkspaceResult {
        workspace {
          id
          members {
            nodes {
              __typename
              id
              created
              user {
                ... on User {
                  __typename
                  id
                  email
                }
              }
            }
          }
        }
      }
      ... on Error {
        code
        message
      }
    }
  }
`;

export function useDeleteWorkspaceMemberMutation() {
  return Urql.useMutation<
    DeleteWorkspaceMemberMutation,
    DeleteWorkspaceMemberMutationVariables
  >(DeleteWorkspaceMemberDocument);
}
export const GetWorkspaceMembersDocument = gql`
  query GetWorkspaceMembers($workspaceId: String!) {
    workspace(id: $workspaceId) {
      ... on Workspace {
        __typename
        members {
          nodes {
            __typename
            id
            created
            user {
              ... on User {
                __typename
                id
                email
              }
            }
          }
        }
      }
      ...onError
    }
  }
  ${OnErrorFragmentDoc}
`;

export function useGetWorkspaceMembersQuery(
  options: Omit<Urql.UseQueryArgs<GetWorkspaceMembersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetWorkspaceMembersQuery,
    GetWorkspaceMembersQueryVariables
  >({ query: GetWorkspaceMembersDocument, ...options });
}
export const ToggleLinkSharingDocument = gql`
  mutation ToggleLinkSharing($workspaceId: ID!, $enable: Boolean!) {
    toggleWorkspaceInvite(workspaceId: $workspaceId, enable: $enable) {
      __typename
      ... on ToggleWorkspaceInviteResult {
        workspace {
          id
          inviteEnabled
          inviteCode
        }
      }
      ... on Error {
        code
        message
      }
    }
  }
`;

export function useToggleLinkSharingMutation() {
  return Urql.useMutation<
    ToggleLinkSharingMutation,
    ToggleLinkSharingMutationVariables
  >(ToggleLinkSharingDocument);
}
export const UpdateWorkspaceDocument = gql`
  mutation UpdateWorkspace(
    $workspaceId: String!
    $update: UpdateWorkspaceInput!
  ) {
    updateWorkspace(workspaceId: $workspaceId, update: $update) {
      ... on Workspace {
        __typename
        id
        name
      }
      ... on Error {
        __typename
        code
        message
      }
    }
  }
`;

export function useUpdateWorkspaceMutation() {
  return Urql.useMutation<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >(UpdateWorkspaceDocument);
}
