import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@fragment/ui/src/components/Button/BaseButton/Button';

type Props = {
  active?: boolean;
};

export const LogoutButton = ({ active = false }: Props) => {
  const { logout } = useAuth0();
  return (
    <Button
      type="button"
      display="flex"
      fontSize="text-fnormal"
      color="text-main"
      background={`${active ? 'bg-canvas ' : ''}hover:bg-negative`}
      fontWeight="font-light"
      padding="py-f1 px-f2"
      w="w-full"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Logout
    </Button>
  );
};
