import clsx from 'clsx';
import { Drawer, DrawerContext } from 'components/Drawer';
import {
  ToastManagerContext,
  ToastRoot,
  useToastManager,
} from 'components/ToastManager';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { type ReactNode, Children, useMemo, useState } from 'react';
import { useWorkspace } from 'wrappers/WorkspaceProvider';
import { WorkspaceRequired } from 'wrappers/WorkspaceRequired/WorkspaceRequired';

import { TextButton } from '@fragment/ui/src/components/Button/TextButton/TextButton';
import { Icon } from '@fragment/ui/src/components/Icon/BaseIcon/Icon';

import { BREAKPOINTS } from '../../constants';

type Props =
  | (
      | {
          mainPageUrl?: never;
          children: [ReactNode, ReactNode];
        }
      | {
          mainPageUrl?: string;
          children: [ReactNode, ReactNode, ReactNode];
        }
    ) & {
      breadcrumb?: ReactNode;
      footer?: ReactNode;
      capPanelWidth?: boolean;
      onSidebarClose?: VoidFunction;
      forceSidebarDrawer?: boolean;
      forceCollapseNav?: boolean;
    };

export const Layout = ({
  footer,
  children,
  mainPageUrl,
  breadcrumb,
  capPanelWidth = true,
  forceSidebarDrawer = false,
  forceCollapseNav = false,
  onSidebarClose,
}: Props) => {
  const childrenArray = Children.toArray(children);
  const [windowWidth] = useWindowSize();
  const { workspace } = useWorkspace();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const drawerContext = useMemo(
    () => ({
      close: () => setIsMenuOpen(false),
      open: () => setIsMenuOpen(true),
    }),
    [setIsMenuOpen]
  );

  const { currentToast, showToast, dismissCurrentToast } = useToastManager();
  const toastContext = useMemo(
    () => ({ showToast, dismissCurrentToast }),
    [showToast, dismissCurrentToast]
  );

  const showDetailPane = !!childrenArray[2] || !!currentToast;
  const leftPane =
    windowWidth < BREAKPOINTS.TABLET || forceCollapseNav ? (
      !showDetailPane &&
      isMenuOpen && (
        <Drawer position="left" trigger="action" onClose={drawerContext.close}>
          {childrenArray[0]}
        </Drawer>
      )
    ) : (
      <div className="min-w-[204px]">{childrenArray[0]}</div>
    );

  const redirectUrl = mainPageUrl || '/';
  const rightSidebarDrawer = onSidebarClose ? (
    <Drawer
      // adding a key so react won't flash the loading state
      key="right-drawer"
      position="right"
      trigger="action"
      onClose={onSidebarClose}
      size={capPanelWidth ? 'small' : 'large'}
    >
      {childrenArray[2]}
    </Drawer>
  ) : (
    <Drawer
      // adding a key so react won't flash the loading state
      key="right-drawer"
      position="right"
      trigger="navigate"
      href={redirectUrl}
      closable={!!mainPageUrl}
      size={capPanelWidth ? 'small' : 'large'}
    >
      {childrenArray[2]}
    </Drawer>
  );
  const rightPane =
    showDetailPane &&
    (windowWidth < BREAKPOINTS.WIDE_TABLET || forceSidebarDrawer ? (
      rightSidebarDrawer
    ) : (
      <div
        data-testid="right-sidebar"
        className={clsx([
          'flex max-w-[450px] grow flex-shrink-0 mt-f10 overflow-y-auto z-[999]',
          capPanelWidth ? 'min-w-[347px] ml-f6 pl-f2' : 'min-w-f48 ml-f4',
        ])}
      >
        {childrenArray[2]}
      </div>
    ));
  return (
    <ToastManagerContext.Provider value={toastContext}>
      <DrawerContext.Provider value={drawerContext}>
        {/* First div is to manage min and max widths, full-screen the height and
         * setup the first flexbox to control the spacing between the main and right panes */}
        <div
          className={clsx([
            'flex flex-row flex-nowrap p-f2 tablet:p-f4 min-w-[600px] justify-between h-screen overflow-hidden relative',
            capPanelWidth && 'max-w-[1440px]',
          ])}
        >
          {/* This flexbox sets up the main pane to flex as needed up to a max */}
          <div
            className={clsx([
              'flex flex-row flex-nowrap min-w-f0 flex-[5_1_auto]',
              capPanelWidth && 'max-w-[1152px]',
            ])}
          >
            {leftPane}
            <div className="flex flex-col grow justify-between min-w-f0 min-h-f0">
              <div
                className={clsx([
                  'min-w-f0 min-h-f0 flex flex-col',
                  capPanelWidth
                    ? 'mx-f0 tablet:mx-f2 max-w-[918px]'
                    : 'ml-f0 h-full',
                  forceCollapseNav ? 'space-y-f0' : 'space-y-f6',
                ])}
              >
                <div className="min-h-f2 flex flex-row space-x-f4 tablet:space-x-f0 mb-f1">
                  {/* TODO: This is an unnessary span. You should be able to pass these props into TextButton */}
                  {(windowWidth < BREAKPOINTS.WIDE_TABLET ||
                    forceCollapseNav) && (
                    <span className="mr-f1">
                      <TextButton
                        onClick={() => drawerContext.open()}
                        disabled={showDetailPane}
                      >
                        <Icon type="hamburger" />
                      </TextButton>
                    </span>
                  )}
                  {workspace && breadcrumb}
                </div>
                <WorkspaceRequired className="pt-f3">
                  {childrenArray[1]}
                </WorkspaceRequired>
              </div>
              {footer}
            </div>
          </div>
          {rightPane}
          {currentToast && <ToastRoot>{currentToast}</ToastRoot>}
        </div>
      </DrawerContext.Provider>
    </ToastManagerContext.Provider>
  );
};
