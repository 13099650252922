import React from 'react';

import { Button } from '../BaseButton/Button';

type BaseButtonProps = Omit<
  JSX.IntrinsicElements['button'],
  'color' | 'type'
> & {
  inverted?: boolean;
};

export const TextButton = ({
  onClick,
  children,
  inverted,
  disabled,
  ...props
}: BaseButtonProps): JSX.Element => (
  <Button
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    onClick={onClick}
    type="button"
    disabled={disabled}
    cursor={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
    fontSize="text-fnormal"
    color={
      inverted
        ? 'text-main hover:text-main-600'
        : 'text-main-600 hover:text-main'
    }
  >
    {children}
  </Button>
);
