import clsx from 'clsx';
import { useWorkspace } from 'hooks/useWorkspace';
import React from 'react';
import { Link as WouterLink } from 'wouter';
import { Menu } from '@headlessui/react';

import { Icon } from '@fragment/ui/src/components/Icon';
import { Text } from '@fragment/ui/src/components/Text';

type Props = {
  className?: string;
};

const getBgColor = ({
  active,
  disabled,
}: {
  active: boolean;
  disabled: boolean;
}) => {
  const bgColor = active && !disabled ? 'bg-canvas' : 'bg-transparent';
  const bgHoverColor = disabled ? '' : ' hover:bg-negative';
  return `${bgColor}${bgHoverColor}` as const;
};

export const WorkspaceSwitcher = ({ className }: Props) => {
  const {
    workspace: currentWorkspace,
    buildNewWorkspaceUrl,
    workspaces,
  } = useWorkspace();

  return (
    <div className={clsx('relative min-w-f0 w-full', className)}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button data-testid="current-display-ws">
              <Text
                display="flex"
                flexContainer="items-center"
                fontSize="text-fnormal"
                maxW="max-w-f18"
                fontWeight="font-normal"
                transition="transition-colors"
                color={open ? 'text-main' : 'text-main-500 hover:text-main'}
              >
                <Text as="div" truncate>
                  {currentWorkspace?.name || 'Select a Workspace'}
                </Text>
                <div>
                  &nbsp;
                  <Icon type="select" />
                </div>
              </Text>
            </Menu.Button>
            <Menu.Items
              className="absolute top-f3 -left-f0 tablet:-left-f2 w-full border border-main-400 bg-canvas max-h-f33 overflow-y-scroll"
              data-testid="ws-dropdown"
            >
              {workspaces.map(({ id, name }) => {
                const workspaceActive = id === currentWorkspace?.id;
                return (
                  <Menu.Item
                    key={id}
                    data-testid={id}
                    disabled={workspaceActive}
                  >
                    {({ active }) => (
                      <WouterLink href={`~${buildNewWorkspaceUrl(id)}`}>
                        <Text
                          as="a"
                          title={name}
                          cursor={
                            workspaceActive
                              ? 'cursor-default'
                              : 'cursor-pointer'
                          }
                          color={
                            workspaceActive ? 'text-main-600' : 'text-main'
                          }
                          background={getBgColor({
                            active,
                            disabled: workspaceActive,
                          })}
                          transition="transition-colors"
                          display="block"
                          w="w-full"
                          h="h-f4"
                          padding="py-f1 px-f2"
                          fontSize="text-fnormal"
                          fontWeight="font-light"
                          truncate
                        >
                          {name}
                        </Text>
                      </WouterLink>
                    )}
                  </Menu.Item>
                );
              })}
              <Menu.Item data-testid="new-ws">
                {({ active }) => (
                  <WouterLink href="~/add-new-workspace">
                    <Text
                      as="a"
                      color="text-main"
                      background={`${
                        active ? 'bg-canvas ' : ''
                      }hover:bg-negative`}
                      transition="transition-colors"
                      display="block"
                      w="w-full"
                      h="h-f4"
                      padding="py-f1 px-f2"
                      fontSize="text-fnormal"
                      fontWeight="font-light"
                      truncate
                    >
                      New +
                    </Text>
                  </WouterLink>
                )}
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};
