import { TopLevelLoader } from 'components/TopLevelLoader';
import { useFlagEnabledWithOverrides } from 'hooks/useFlagEnabledWithOverrides';
import { useUserInfo } from 'hooks/useUserInfo';
import React, { useEffect } from 'react';
import { getDefaultRoute } from 'utils/defaultRoute';
import { getLocalStorage, removeLocalStorage } from 'utils/localStorage';
import { useLocation } from 'wouter';
import { useWorkspace } from 'wrappers/WorkspaceProvider';

export const DefaultRedirect = () => {
  const { workspaces } = useWorkspace();
  const [, setLocation] = useLocation();
  const { type } = useUserInfo();

  const defaultWorkspaceId = getLocalStorage('default_workspace_id')?.id;
  const postLoginRedirect = getLocalStorage('post_login_redirect');

  useEffect(() => {
    let defaultRoute: string =
      type === 'not_logged_in' ? '/login' : `/add-new-workspace`;

    const redirectWorkspaceId =
      workspaces.find((ws) => ws.id === defaultWorkspaceId)?.id ??
      workspaces[0]?.id;
    if (redirectWorkspaceId) {
      defaultRoute = getDefaultRoute(
        redirectWorkspaceId,
        postLoginRedirect ?? 'home'
      );
      removeLocalStorage('post_login_redirect');
    }
    setLocation(defaultRoute);
  });
  return <TopLevelLoader />;
};
