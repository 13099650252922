import type { Booleans, Pipe, Tuples } from 'hotscript';

import type { TWFontSize, TWHeight, TWWidth } from '../types/tailwind';

const withOpacityValue = (variable: string) =>
  `rgb(var(${variable}) / <alpha-value>)`;

export const defaultPalette = {
  canvas: {
    DEFAULT: withOpacityValue('--colour-canvas'),
  },
  main: {
    // main-XX is deprecated, use main-XXX instead
    DEFAULT: withOpacityValue('--colour-main'),

    // Dark mode compatible grayscale
    200: withOpacityValue('--colour-main-200'),
    400: withOpacityValue('--colour-main-500'),
    500: withOpacityValue('--colour-main-500'),
    600: withOpacityValue('--colour-main-600'),
    800: withOpacityValue('--colour-main-800'),
    900: withOpacityValue('--colour-main-900'),
  },
  negative: withOpacityValue('--colour-negative'),

  // ALIE
  'asset-green': withOpacityValue('--colour-asset-green'),
  'liability-red': withOpacityValue('--colour-liability-red'),
  'income-blue': withOpacityValue('--colour-income-blue'),
  'expense-yellow': withOpacityValue('--colour-expense-yellow'),

  // Code Colours
  amber: withOpacityValue('--colour-amber'),
  orange: withOpacityValue('--colour-orange'),
  royal: withOpacityValue('--colour-royal'),
  forest: withOpacityValue('--colour-forest'),
  purple: withOpacityValue('--colour-purple'),
  magenta: withOpacityValue('--colour-magenta'),
} as const;

type MainValues = keyof (typeof defaultPalette)['main'];
export type DefaultPalette =
  | `${keyof typeof defaultPalette}`
  | `main-${MainValues}`;

export const fontSizeTailwindConfig = {
  fnormal: ['14px', '18px'],
  s20: ['20px', '26px'],
  flg: ['32px', '36px'],
  fxl: ['50px', '63px'],
} as Record<'fnormal' | 'flg' | 'fxl' | 's20', [string, string]>;
export type TextSizeType = keyof typeof fontSizeTailwindConfig;

export const sizeToTextClassName = {
  fnormal: 'text-fnormal',
  s20: 'text-s20',
  flg: 'text-flg',
  fxl: 'text-fxl',
} as const;

export const sizeToSvgWidth = {
  fsmall: 5,
  fnormal: 12,
  s20: 20,
  flg: 32,
  fxl: 64,
} as const;
export type IconSizeType = keyof typeof sizeToSvgWidth;

export const colorToHexCode = {
  transparent: 'transparent',
  currentColor: 'currentColor',
  lightgray: '#EBEBEB',
  gray200: '#E7E7E7',
  gray400: '#D7D7D7',
  gray500: '#B5B5BB',
  gray700: '#565656',
  gray800: '#414141',
  gray900: '#242526',
  gray: '#909090',
  darkgray: '#414141',
  black: '#0D0D0D',
  'codeblock-gray': '#242526',
  keyline: '#CCCCCC',
  foundation: '#F4F4F4',
  white: '#FFFFFF',
  orange: '#FF9000',
  forest: '#449A00',
  sea: '#00B292',
  royal: '#0735F5',
  magenta: '#FF00D7',
  'asset-green': '#5AC93B',
  'liability-red': '#EC3425',
  'income-blue': '#53BEF9',
  'expense-yellow': '#FFE000',
  amber: '#FF4900',
  purple: '#A53DE7',
} as const;
export type ColorType = keyof typeof colorToHexCode;

export const colorToTextClassName = {
  transparent: 'text-transparent',
  currentColor: 'text-currentColor',
  orange: 'text-orange',
  'codeblock-gray': 'text-codeblock-gray',
  forest: 'text-forest',
  sea: 'text-sea',
  gray: 'text-gray',
  gray200: 'text-gray200',
  gray400: 'text-gray400',
  gray500: 'text-gray500',
  gray700: 'text-gray700',
  gray800: 'text-gray800',
  gray900: 'text-gray900',
  black: 'text-black',
  lightgray: 'text-lightgray',
  darkgray: 'text-darkgray',
  keyline: 'text-keyline',
  foundation: 'text-foundation',
  white: 'text-white',
  royal: 'text-royal',
  magenta: 'text-magenta',
  'asset-green': 'text-asset-green',
  'liability-red': 'text-liability-red',
  'income-blue': 'text-income-blue',
  'expense-yellow': 'text-expense-yellow',
  amber: 'text-amber',
  purple: 'text-purple',
} as const;

export const assetTypeToBackgroundColor = {
  asset: 'asset-green',
  liability: 'liability-red',
  income: 'income-blue',
  expense: 'expense-yellow',
} as const;

export type BaseSvgProps = {
  'data-testid'?: string;
  width?: number;
  backgroundColorHexCode?: string;
  primaryColorHexCode?: string;
  includeSecondaryMark?: boolean;
};

export const AccountTypes = [
  'asset',
  'liability',
  'income',
  'expense',
] as const;
export type AccountType = (typeof AccountTypes)[number];

const GradientColorsNames = [
  'codeblock-copy-texture',
  'sticky-header-background',
  'reverse-sticky-header-background',
] as const;
export type TWGradientColorValues = (typeof GradientColorsNames)[number];

const SpacingNames = [
  'fone-third',
  'ftwo-thirds',
  'fhalf',
  'f0',
  'f1',
  'f2',
  'f3',
  'f4',
  'f5',
  'f6',
  'f7',
  'f8',
  'f9',
  'f10',
  'f11',
  'f12',
  'f13',
  'f14',
  'f15',
  'f16',
  'f17',
  'f18',
  'f19',
  'f20',
  'f21',
  'f22',
  'f23',
  'f24',
  'f25',
  'f28',
  'f30',
  'f32',
  'f33',
  'f40',
  'f41',
  'f45',
  'f48',
  'f56',
  'f62',
  'f66',
] as const;
export type TWSpacingValues = (typeof SpacingNames)[number];

export type BaseLineIconProps = {
  type: AccountType;
  size?: IconSizeType;
  disabled?: boolean;
};

export const getHeight = <T>(size: TextSizeType): TWHeight<T> => {
  let height: string;
  switch (size) {
    case 'fnormal':
      height = 'h-f4';
      break;
    case 'flg':
      height = 'h-f6';
      break;
    case 'fxl':
      height = 'h-f8';
      break;
    default:
      throw new Error(`getHeight not implemented for size ${size}`);
  }
  return height as TWHeight<T>;
};

export const getTextSize = <T>(size: TextSizeType): TWFontSize<T> => {
  let textSize: string;
  switch (size) {
    case 'fnormal':
      textSize = 'text-fnormal';
      break;
    case 'flg':
      textSize = 'text-flg';
      break;
    case 'fxl':
      textSize = 'text-fxl';
      break;
    default:
      throw new Error(`getTextSize not implemented for size ${size}`);
  }
  return textSize as TWFontSize<T>;
};

export const getWidth = <T>(size: TextSizeType): TWWidth<T> => {
  let width: string;
  switch (size) {
    case 'fnormal':
      width = 'w-f41';
      break;
    case 'fxl':
      width = 'w-f62';
      break;
    default:
      // TODO: Implement for flg
      throw new Error(`getWidth not implemented for size ${size}`);
  }
  return width as TWWidth<T>;
};

/**
 * Utility type that infers the type of individual elements in
 * a list and reconstructs a list of inferred types.
 *
 * Consider using Join instead.
 */
type Cons<Head, Tail> = Head extends []
  ? []
  : Tail extends []
  ? [Head]
  : Tail extends [infer A, ...infer B]
  ? [Head, ...Cons<A, B>]
  : never;

/**
 * Utility type that returns a list of inferred types of
 * individual elements in a list, or never.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Join<T extends readonly any[]> = T extends readonly [
  infer Head,
  ...infer Tail
]
  ? Cons<Head, Tail>
  : never;

/**
 * Utility type that takes a list of strings and false and returns a
 * space-delimited string containing the list of strings, while discarding
 * the false values.
 */
type StringifyClassNames<T extends readonly (string | false)[]> = Pipe<
  Join<T>,
  [Tuples.Filter<Booleans.DoesNotExtend<false>>, Tuples.Join<' '>]
>;

type StringifyClassNamesResult<T extends readonly (string | false)[] | string> =
  T extends readonly (string | false)[] ? StringifyClassNames<T> : T;

export const strictClassNames = <
  T extends readonly (string | false)[] | string
>(
  args: T
): StringifyClassNamesResult<T> => {
  if (Array.isArray(args)) {
    return args.filter((a) => !!a).join(' ') as StringifyClassNamesResult<T>;
  }
  if (typeof args === 'string') {
    return args as StringifyClassNamesResult<T>;
  }
  throw new Error('Unreachable code');
};
